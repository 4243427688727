.App-footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 3vmin;
  padding-bottom: 3vmin;
  background-color: #282c34;
}

.App-footer * {
  color: white !important;
  font-size: calc(10px + 0.25vmin);
}