body {
  background-color: #222;
  color: #ddd;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0 0 0;
}

a {
  text-decoration: none;
  color: #ff0048;
  text-transform: uppercase;
}

a:hover {
  text-decoration: none;
  color: #222;
}

.background {
  top: 0;
  margin-top: 0;
  padding-top: 0;
  /* background-image: url("./images/city-3814205.jpg"); */
  background-color: #333;
  background-attachment: fixed;
  background-size: cover;
}

.canvas {
  top: 0;
  margin-top: 0;
  padding-top: 0;
  background-attachment: fixed;
  background-size: cover;
}

.canvas .overlay {
  backdrop-filter: blur(100px);
}

.App {
  text-align: center;
  background-color: #222;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro {
  width: 60% !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.logo {
  width: 45%
}

.logo img {
  width: 55%;
}


.what-we-do {
  text-align: left;
  line-height: 400%;
  width: 70%;
  color: rgb(38, 220, 86);
}